import { Injectable, signal } from '@angular/core';
import { Candidate } from "../model/candidate";
import { PocketbaseService } from "../shared/pocketbase.service";
import { User } from "../model/user";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  $candidate = signal<Candidate | undefined>(undefined);
  $user = signal<User | undefined>(undefined);

  constructor(private pb: PocketbaseService) {
  }

  loadCandidate() {
    if (this.pb.instance().authStore.model) {
      this.pb.instance().collection('candidate').getFirstListItem<Candidate>(`user='${this.pb.instance().authStore.model!['id']}'`, { expand: "user, positions, positions.role" }).then((result) => {
        this.$candidate.set(result);
      }).catch(e => console.log('No candidate created yet'));
      this.pb.instance().collection('users').getOne<User>(this.pb.instance().authStore.model!['id']).then((result) => {
        this.$user.set(result);
      }).catch(e => console.log(e));
    }
  }
}
