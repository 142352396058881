import { inject, PLATFORM_ID } from '@angular/core';

import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from "../local-storage.service";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

export const authGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const platformId = inject(PLATFORM_ID);
  const router = inject(Router);
  const localStorageService = inject(LocalStorageService);

  if (isPlatformBrowser(platformId) && !!localStorageService.getData('pocketbase_auth')) {
    return true;
  } else {
    router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
};

