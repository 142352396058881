<div class="row app-content__frame-inner">
  <div class="main-col col-12 col-md-7 col-lg-8">
    <div class="main-col__content">
      <h1>{{ loginHeading }}</h1>
      @if (toConfirmCandidate) {
        <p>
          Du har fått en förfrågan om att bekräfta tidigare arbetsroller för en kandidat på Moderna Ledare.</p>
        <p>
          För att säkerställa att endast behöriga personer kan bekräfta denna information, ber vi dig att logga in med ditt LinkedIn-konto. När du har loggat in kommer du att kunna se kandidatens tidigare roller och bekräfta att informationen stämmer.
        </p>
      } @else {
        <p>
            För att förenkla vid inloggning ber vi dig att logga in via LinkedIn.
        </p>
        <p>
          Om du skapar en profil kommer du att få bjuda in två kontakter på LinkedIn som validerar att du är du, samt att
          en av dina erfarenheter ser korrekt ut. Detta är för att säkra att de kandidater vi har håller så hög kvalitet som möjligt.
        </p>
        <p>
          Vi ber om din hjälp för att möjliggöra en hög nivå i innehållet.
        </p>
      }
      <div class="d-flex justify-content-center pt-4">
        <button (click)="login()" class="btn btn-lg btn-primary"><i class="bi-linkedin"></i> Logga in med LinkedIn
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-5 col-lg-4 pt-5 pt-md-0">
    <app-support-info></app-support-info>
  </div>
</div>
