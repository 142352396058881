<h1>Radera din profil</h1>
<div>
  <p>Är du säker på att du vill radera din profil?</p>
  <p>
    <input (click)="deleteProfile()" class="btn btn-lg btn-secondary" name="delete" type="submit"
           value="Ja, radera min profil">
  </p>
  <p>
    <a class="btn btn-lg btn-primary" routerLink="/profil">Nej, gå tillbaka</a>
  </p>
</div>


