<div class="row app-content__frame-inner">
  <div class="main-col col-12 col-md-7 col-lg-8">
    <div class="main-col__content">
      @if (candidateToConfirm() && candidateToConfirm()?.expand?.user?.id !== user()?.id) {
        <h1>Bekräfta tidigare arbetsroller</h1>
        <p>Hej!<br/><br/><strong>{{ candidateToConfirm()?.expand?.user?.name }}</strong> är intresserad av att bli en <strong>Modern Ledare</strong> och har bjudit in dig för att bekräfta en eller flera av sina tidigare roller.</p>
        <p>Moderna Ledare matchar kandidater med interimsuppdrag och för oss är viktigt att hålla så hög kvalitet som mjöligt i vår process. Därför ber vi dig om hjälp att bekräfta viss information.</p>
        <p>Om du har arbetat tillsammans tidigare eller känner till arbetet, kan du bekräfta en eller flera roller genom att klicka på “Bekräfta”.</p>
        <p>Om du inte känner till arbetet, kontakta gärna personen som skickade länken. Kanske kan profilen behöva uppdateras.</p>
        <app-validation-presentation [candidateToConfirm]="candidateToConfirm()"
                                     [userToConfirm]="candidateToConfirm()?.expand?.user"
                                     [validateFn]="confirmCandidate"></app-validation-presentation>
        <div class="thank-you">Tack för din hjälp!</div>
      } @else {
        <h1>Du kan inte validera dig själv</h1>
        <p>
          Skicka länken till några av dina kontakter på LinkedIn som kan validera dig.
        </p>
      }
    </div>
  </div>
  <div class="col-12 col-md-5 col-lg-4 pt-5 pt-md-0">
    <app-support-info></app-support-info>
  </div>
</div>
