import { Component } from '@angular/core';


@Component({
  selector: 'app-support-info',
  standalone: true,
  imports: [],
  templateUrl: './support-info.component.html',
  styleUrl: './support-info.component.scss'
})
export class SupportInfoComponent {

}
