import { Component, OnInit } from '@angular/core';

import { RouterOutlet } from '@angular/router';
import { NavigationComponent } from "./navigation/navigation.component";
import { ProfileService } from "./profile/profile.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NavigationComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  constructor(private profileService: ProfileService) {
  }

  ngOnInit() {
    this.profileService.loadCandidate();
  }
}
