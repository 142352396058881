import { Injectable } from '@angular/core';
import PocketBase from "pocketbase";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PocketbaseService {

  pb: PocketBase;

  constructor() {
    this.pb = new PocketBase(environment.pocketbaseUrl);
  }

  instance(): PocketBase {
    return this.pb;
  }
}
