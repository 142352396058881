import { Component, computed, Input } from '@angular/core';
import { Candidate } from "../../model/candidate";
import { PocketbaseService } from "../../shared/pocketbase.service";
import { ProfileService } from "../profile.service";
import { ToastrService } from "ngx-toastr";
import { Title } from "@angular/platform-browser";
import { RouterLink } from "@angular/router";
import { environment } from "../../../environments/environment";


@Component({
  selector: 'app-upload-cv',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './upload-cv.component.html',
  styleUrl: './upload-cv.component.scss'
})
export class UploadCVComponent {
  candidate;
  user;
  cvUrl = computed(() => this.candidate()?.cv ? `${environment.pocketbaseUrl}/api/files/candidate/${this.candidate()?.id}/${this.candidate()?.cv}` : "");

  @Input()
  wizard = false;
  saveButtonText = computed(() => this.wizard ? "Nästa" : "Spara");

  constructor(private pb: PocketbaseService,
              private profileService: ProfileService,
              private toastr: ToastrService,
              private title: Title) {
    this.candidate = this.profileService.$candidate;
    this.user = this.profileService.$user;
    title.setTitle("Ladda upp CV");
  }

  uploadCV(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      this.pb.instance().collection<Candidate>("candidate").update(this.candidate()?.id ?? "", {
        "cv": file,
        "status": "READY"
      }).then(updated => {
        this.profileService.loadCandidate();
        this.toastr.success("Ditt nya CV är uppladdat", "CV uppladdat");
      }).catch(error => {
        this.toastr.error("Det gick inte att ladda upp ditt CV. Se till att det är i Word-format och försök igen.", "Något gick fel");
      });
    }
  }
}
