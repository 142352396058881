<div class="card bg-light mb-3">
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex align-items-center mb-2">
          @if (userToConfirm?.avatarUrl) {
            <img class="portrait" src="{{userToConfirm?.avatarUrl}}">
          }
          <h3 class="border--bottom ms-2">{{ userToConfirm?.name }}</h3>
        </div>
      </div>
      <div class="col-12">
        <div class="table-responsive table-responsive-stack">
          <table class="table align-middle">
            <thead>
            <tr>
              <th scope="col">Roll</th>
              <th scope="col">Företag</th>
              <th scope="col">Antal år</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
              @for (position of candidateToConfirm?.expand?.positions; track position) {
                <tr>
                  <td data-label="Roll">{{ position.expand.role.name }}</td>
                  <td data-label="Företag">{{ position.companyName }}</td>
                  <td data-label="Antal år">{{ position.years }} år</td>
                  <td>
                    <button (click)="validateFn(position)" [class.btn-outline-secondary]="!confirmationEnabled"
                            [disabled]="!confirmationEnabled"
                            class="btn btn-success">Bekräfta
                    </button>
                  </td>
                </tr>
              } @empty {
                <tr>
                  <td colspan="4"><em>Det finns inga roller att bekräfta</em></td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
@if (!candidateToConfirm?.expand?.positions) {
  <div class="alert alert-warning">
    Du har inte lagt till några roller, gå tillbaka och lägg till de som är relevanta.
  </div>
}