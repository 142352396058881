import { Component, OnInit } from '@angular/core';

import { PocketbaseService } from "../../shared/pocketbase.service";
import { LocalStorageService } from "../../shared/local-storage.service";
import {
  AssignmentType,
  Availability,
  budgetLevels,
  Candidate,
  ChangingOrganizations,
  Located,
  manageDirectLevel,
  manageIndirectLevel,
  MyNeedsIndicators,
  OwnCompany,
  ValuesAsLeader
} from "../../model/candidate";

@Component({
  selector: 'app-candidate-list',
  standalone: true,
  imports: [],
  templateUrl: './candidate-list.component.html',
  styleUrl: './candidate-list.component.scss'
})
export class CandidateListComponent implements OnInit {
  result: Candidate[] = [];
  Located = Located;
  protected readonly ManageDirectLevel = manageDirectLevel;
  protected readonly ManageIndirectLevel = manageIndirectLevel;
  protected readonly BudgetLevels = budgetLevels;
  protected readonly MyNeedsIndicators = MyNeedsIndicators;
  protected readonly ChangingOrganizations = ChangingOrganizations;
  protected readonly ValuesAsLeader = ValuesAsLeader;
  protected readonly AssignmentType = AssignmentType;
  protected readonly OwnCompany = OwnCompany;
  protected readonly Availability = Availability;

  constructor(private pb: PocketbaseService, private localStorage: LocalStorageService) {
  }

  ngOnInit(): void {
    this.pb.instance().collection('candidate')
      .getList<Candidate>(1, 50, { expand: "user,preferedLocations,preferedIndustries,languageLevels,languageLevels.language,positions,positions.role" })
      .then((result) => {
        this.result = result.items;
        console.log(result.items[0]);
      }).catch((error) => {
      console.log('Error:', error);
    });
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  keyToText(array: any[], key: any) {
    return array.filter(value => value.key === key)[0]?.text;
  }

  keyToShortText(array: any[], key: any) {
    return array.filter(value => value.key === key)[0]?.short;
  }
}
