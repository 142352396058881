import { Component, OnInit, WritableSignal } from '@angular/core';

import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { User } from "../model/user";
import { PocketbaseService } from "../shared/pocketbase.service";
import { LocalStorageService } from "../shared/local-storage.service";
import { ProfileService } from "../profile/profile.service";
import { NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Candidate } from "../model/candidate";
import { NgIf } from "@angular/common";

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [RouterLink, NgbCollapseModule, RouterLinkActive, NgbDropdownModule, NgIf],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss'
})

export class NavigationComponent implements OnInit {

  isCollapsed = true; // Collapsed by default

  user!: WritableSignal<User | undefined>;
  candidate!: WritableSignal<Candidate | undefined>;

  constructor(private pb: PocketbaseService, private localStorage: LocalStorageService, private profileService: ProfileService, private router: Router) {
  }


  logout() {
    this.pb.instance().authStore.clear();
    this.profileService.$user.set(undefined);
    this.profileService.$candidate.set(undefined);
    this.router.navigate(['/']);
  }

  ngOnInit(): void {
    this.user = this.profileService.$user;
    this.candidate = this.profileService.$candidate;
  }
}
