import { Component } from '@angular/core';

@Component({
  selector: 'app-profile-deleted',
  standalone: true,
  imports: [],
  templateUrl: './profile-deleted-confirmation.component.html',
  styleUrl: './profile-deleted-confirmation.component.scss'
})
export class ProfileDeletedConfirmationComponent {

}
