<h1>Få dina tidigare roller bekräftade</h1>
<p>För att du ska kunna bli matchad mot uppdrag måste två av dina kontakter på Linkedin bekräfta minst
  en av dina roller.</p>
<p>Nedan ser du vilken information de kommer att se:</p>
<div class="card-body">
  <app-validation-presentation [candidateToConfirm]="candidate()" [confirmationEnabled]="false"
                               [userToConfirm]="user()"></app-validation-presentation>
</div>
<div class="card">
  <div class="card-body">
    <p>
      <strong>Skicka följande länk till de du vill ska bekräfta dina tidigare roller:</strong>
    </p>
    <p class="confirmation-link-and-button">
      <span class="confirmation-link-and-button__url">{{ linkedinUrl() }}</span>
      <button (click)="toastrCopiedLink()" [cdkCopyToClipboard]="linkedinUrl()" class="btn btn-success"><i
        class="bi-copy"></i> Kopiera länk
      </button>
    </p>
  </div>
</div>
<div class="mt-3 d-flex justify-content-between">
  @if (wizard) {
    <button [queryParams]="{wizard:true}" [routerLink]="'/profil/registrera-dig'" class="btn btn-primary btn-lg"
            type="submit">
      Tillbaka
    </button>
  }
  @if (wizard) {
    <button [queryParams]="{wizard:true}" [routerLink]="'/profil/cv'" class="btn btn-primary btn-lg"
            type="submit">Nästa
    </button>
  }
  @if (!wizard) {
    <button [routerLink]="'/profil'" class="btn btn-primary btn-lg" type="submit">Spara</button>
  }
</div>
