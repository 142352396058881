import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { Router, RouterLink } from "@angular/router";
import { ProfileService } from "../profile.service";
import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { ConfirmPresentationComponent } from "../../confirm/confirm-presentation/confirm-presentation.component";
import { StartComponent } from "../../start/start.component";
import { Title } from "@angular/platform-browser";
import { PocketbaseService } from "../../shared/pocketbase.service";
import { Candidate } from "../../model/candidate";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-my-page',
  standalone: true,
  imports: [RouterLink, ClipboardModule, ConfirmPresentationComponent, StartComponent],
  templateUrl: './my-page.component.html',
  styleUrl: './my-page.component.scss'
})
export class MyPageComponent implements OnInit {

  window = window;
  candidate;
  user;

  constructor(private pb: PocketbaseService,
              private profileService: ProfileService,
              private router: Router,
              private toastr: ToastrService,
              private clipboard: Clipboard, @Inject(LOCALE_ID)
              private locale: string, private title: Title) {
    this.candidate = this.profileService.$candidate;
    this.user = this.profileService.$user;
    title.setTitle("Din sida");
  }

  ngOnInit(): void {
    // if (!this.candidate()?.id) {
    //   this.router.navigate(["/profil/registrera-dig"], { queryParams: { wizard: true } });
    // }
  }

  linkedinUrl() {
    return `${window.location.origin}/bekrafta/${this.candidate()?.id}`;
  }

  availability() {
    switch (this.candidate()?.availability) {
      case "NOW":
        return "Tillgänglig nu";
      case "LATER":
        return "Tillgänglig från " + formatDate(this.candidate()?.availableFrom ?? "", 'shortDate', this.locale);
      default:
        return "Inte satt. Uppdatera din profil";
    }
  }

  interestedIn360() {
    this.pb.instance().collection<Candidate>("candidate").update(this.candidate()?.id ?? "", { "interestedIn360": "INTERESTED" }).then(updated => {
      this.toastr.success("Vi kontaktar dig för att prata mer om en Leadership Agility 360. Vi hörs!", "Leadership Agility 360");
      this.profileService.loadCandidate();
    });
  }

  toastrCopiedLink() {
    this.toastr.success("Länken är kopierad. Nu kan du klistra in den i ditt meddelande till de som ska bekräfta din profil.", "Länk kopierad");
  }
}
