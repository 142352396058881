import { Component } from '@angular/core';

import { RouterLink } from "@angular/router";
import { SupportInfoComponent } from "../shared/support-info/support-info.component";

@Component({
  selector: 'app-start',
  standalone: true,
  imports: [RouterLink, SupportInfoComponent],
  templateUrl: './start.component.html',
  styleUrl: './start.component.scss'
})
export class StartComponent {

}
