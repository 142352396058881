import { Component, computed, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {
  AssignmentType,
  budgetLevels,
  Candidate,
  ChangingOrganizations,
  Located,
  manageDirectLevel,
  manageIndirectLevel,
  MyNeedsIndicators,
  OrgSizes,
  OwnCompany,
  ValuesAsLeader
} from "../../model/candidate";
import { Location } from "../../model/location";
import { debounceTime, distinctUntilChanged, filter, map, merge, Observable, OperatorFunction, Subject } from "rxjs";
import { PocketbaseService } from "../../shared/pocketbase.service";
import { NgbDate, NgbDatepicker, NgbInputDatepicker, NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { Industry } from "../../model/industry";
import { Language } from "../../model/language";
import { Role } from "../../model/role";
import { Router } from "@angular/router";
import { ProfileService } from "../profile.service";
import { ToastrService } from "ngx-toastr";
import { NgForOf } from "@angular/common";


@Component({
  selector: 'app-register',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgbInputDatepicker, NgbTypeahead, NgbDatepicker, NgbInputDatepicker, NgForOf],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit {
  candidateId = "";
  availableLocations: Location[] = [];
  availableIndustries: Industry[] = [];
  availableLanguages: Language[] = [];
  availableRoles: Role[] = [];
  formBuilder = new FormBuilder();
  today = new Date();
  newIndustry = "";
  @Input()
  wizard = false;

  saveButtonText = computed(() => this.wizard ? "Nästa" : "Spara");

  registrationForm: FormGroup<{
    assignmentType: FormControl<string | null>;
    availability: FormControl<string | null>;
    availableFrom: FormControl<NgbDate | null>;
    budgetLevels: FormControl<string | null>;
    changingOrganizations: FormControl<string | null>;
    employeeAppraisals: FormControl<boolean | null>;
    preferedIndustries: FormArray;
    languages: FormArray;
    locatedRemote: FormControl<boolean | null>;
    locatedOnsite: FormControl<boolean | null>;
    locatedCanTravel: FormControl<boolean | null>;
    locations: FormArray;
    manageDirectLevel: FormControl<string | null>;
    manageIndirectLevel: FormControl<string | null>;
    myNeedsIndicator1: FormControl<string | null>;
    myNeedsIndicator2: FormControl<string | null>;
    contactEmail: FormControl<string | null>;
    phoneNumber: FormControl<string | null>;
    orgPhaseScaleup: FormControl<boolean | null>;
    orgPhaseStable: FormControl<boolean | null>;
    orgPhaseStartup: FormControl<boolean | null>;
    orgSize: FormControl<string | null>;
    ownCompany: FormControl<string | null>;
    positions: FormArray;
    preferInterimCoverDuringRecruitment: FormControl<boolean | null>;
    preferInterimCoverDuringSickleave: FormControl<boolean | null>;
    preferInterimLeadChange: FormControl<boolean | null>;
    salaryNegotiations: FormControl<boolean | null>;
    valueAsLeader: FormControl<string | null>;
    valueContext: FormControl<number | null>;
    valuePerspective: FormControl<number | null>;
    valueSelfLeadership: FormControl<number | null>;
    valueSolutions: FormControl<number | null>;
    wantedInterimRoles: FormArray;
  }> = new FormGroup({
    assignmentType: new FormControl('', Validators.required),
    availability: new FormControl('', Validators.required),
    availableFrom: new FormControl(new NgbDate(this.today.getFullYear(), this.today.getMonth() + 1, this.today.getDate())),
    budgetLevels: new FormControl('', Validators.required),
    changingOrganizations: new FormControl('', Validators.required),
    employeeAppraisals: new FormControl(false),
    preferedIndustries: this.formBuilder.array([]),
    languages: this.formBuilder.array([]),
    locatedRemote: new FormControl(false),
    locatedOnsite: new FormControl(false),
    locatedCanTravel: new FormControl(false),
    locations: this.formBuilder.array([]),
    manageDirectLevel: new FormControl('', Validators.required),
    manageIndirectLevel: new FormControl('', Validators.required),
    myNeedsIndicator1: new FormControl('', Validators.required),
    myNeedsIndicator2: new FormControl('', Validators.required),
    contactEmail: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', Validators.required),
    orgPhaseScaleup: new FormControl(false),
    orgPhaseStable: new FormControl(false),
    orgPhaseStartup: new FormControl(false),
    orgSize: new FormControl('', Validators.required),
    ownCompany: new FormControl('', Validators.required),
    positions: this.formBuilder.array([]),
    preferInterimCoverDuringRecruitment: new FormControl(false),
    preferInterimCoverDuringSickleave: new FormControl(false),
    preferInterimLeadChange: new FormControl(false),
    salaryNegotiations: new FormControl(false),
    valueAsLeader: new FormControl('', Validators.required),
    valueContext: new FormControl(1),
    valuePerspective: new FormControl(1),
    valueSelfLeadership: new FormControl(1),
    valueSolutions: new FormControl(1),
    wantedInterimRoles: this.formBuilder.array([])
  });
  @ViewChild("location", { static: false }) location!: NgbTypeahead;
  @ViewChild('industry', { static: true }) industry!: NgbTypeahead;
  @ViewChild('role', { static: true }) role!: NgbTypeahead;
  @ViewChild('wantedInterimRole', { static: true }) wantedInterimRole!: NgbTypeahead;

  locationFocus$ = new Subject<string>();
  locationClick$ = new Subject<string>();
  industryFocus$ = new Subject<string>();
  industryClick$ = new Subject<string>();
  roleFocus$ = new Subject<string>();
  roleClick$ = new Subject<string>();
  wantedInterimRoleFocus$ = new Subject<string>();
  wantedInterimRoleClick$ = new Subject<string>();
  protected readonly Located = Located;
  protected readonly OwnCompany = OwnCompany;
  protected readonly AssignmentType = AssignmentType;
  protected readonly BudgetLevels = budgetLevels;
  protected readonly ManageDirectLevels = manageDirectLevel;
  protected readonly ManageIndirectLevels = manageIndirectLevel;
  protected readonly MyNeedsIndicators = MyNeedsIndicators;
  protected readonly ValuesAsLeader = ValuesAsLeader;
  protected readonly ChangingOrganizations = ChangingOrganizations;
  protected readonly OrgSizes = OrgSizes;
  private locationElement!: NgbTypeahead;

  constructor(private pb: PocketbaseService,
              private profileService: ProfileService,
              private modalService: NgbModal,
              private router: Router,
              private toastr: ToastrService) {
  }

  @ViewChild('location', { static: false }) set content(content: NgbTypeahead) {
    if (content) { // initially setter gets called with undefined since it's not ready yet
      this.locationElement = content;
    }
  }

  get locations() {
    return this.registrationForm.get('locations') as FormArray;
  }

  get positions() {
    return this.registrationForm.get('positions') as FormArray;
  }

  get wantedInterimRoles() {
    return this.registrationForm.get('wantedInterimRoles') as FormArray;
  }

  get industries() {
    return this.registrationForm.get('preferedIndustries') as FormArray;
  }

  get languages() {
    return this.registrationForm.get('languages') as FormArray;
  }

  saveIndustrySuggestion(content: TemplateRef<any>) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        if (this.newIndustry) {
          this.pb.instance().collection<any>('industrySuggestion').create({
            reportedBy: this.profileService.$user()?.id,
            name: this.newIndustry
          }).then(
            (newIndustry) => {
              this.toastr.success(`Vi har tagit emot ${newIndustry.name} som förslag på ny bransch. Tack!`, 'Tack för ditt förslag!');
            }
          );
        }
        this.newIndustry = "";
      },
      (reason) => {
        this.newIndustry = "";
      }
    );
  }

  typeaheadFormatter = (object: Location | Industry) => object.name;

  searchLocations: OperatorFunction<string, readonly { id: any; name: any }[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.locationClick$.pipe(filter(() => !this.locationElement.isPopupOpen()));
    const inputFocus$ = this.locationFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.availableLocations
        : this.availableLocations.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 15))
    );
  }

  searchIndustries: OperatorFunction<string, readonly { id: any; name: any }[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.industryClick$.pipe(filter(() => !this.industry.isPopupOpen()));
    const inputFocus$ = this.industryFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.availableIndustries
        : this.availableIndustries.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 15))
    );
  }
  searchRoles: OperatorFunction<string, readonly { id: any; name: any }[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.roleClick$.pipe(filter(() => !this.role.isPopupOpen()));
    const inputFocus$ = this.roleFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.availableRoles
        : this.availableRoles.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 15))
    );
  }

  searchWantedInterimRoles: OperatorFunction<string, readonly {
    id: any;
    name: any
  }[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.wantedInterimRoleClick$.pipe(filter(() => !this.wantedInterimRole.isPopupOpen()));
    const inputFocus$ = this.wantedInterimRoleFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.availableRoles
        : this.availableRoles.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 15))
    );
  }

  ngOnInit() {
    this.pb.instance().collection('location').getList<Location>(1, 400).then((result) => {
      this.availableLocations = result.items;
    });
    this.pb.instance().collection('industry').getList<Industry>(1, 400).then((result) => {
      this.availableIndustries = result.items;
    });
    this.pb.instance().collection('role').getList<Role>(1, 400).then((result) => {
      this.availableRoles = result.items;
    });
    this.pb.instance().collection('language').getList<Language>(1, 20, { sort: "sortOrder" }).then((result) => {
      this.availableLanguages = result.items;
      this.availableLanguages.forEach(language => {
        this.languages.push(new FormGroup({
          language: new FormControl(language),
          level: new FormControl(language.defaultLevel, Validators.required)
        }));
      });
    });
    // Load previous answers
    this.pb.instance().collection<Candidate>("candidate").getFirstListItem(this.pb.instance().filter("user = {:user}", { user: this.pb.instance().authStore.model!['id'] }), { expand: "preferedIndustries,preferedLocations,positions,positions.role,languageLevels,languageLevels.language,wantedInterimRoles" }).then(savedCandidate => {
      this.candidateId = savedCandidate.id;
      let availableFrom = new Date(savedCandidate.availableFrom);
      this.registrationForm = new FormGroup({
        assignmentType: new FormControl(savedCandidate.assignmentType, Validators.required),
        availability: new FormControl(savedCandidate.availability, Validators.required),
        availableFrom: new FormControl(new NgbDate(availableFrom.getFullYear(), availableFrom.getMonth() + 1, availableFrom.getDate())),
        budgetLevels: new FormControl(savedCandidate.budgetLevels, Validators.required),
        changingOrganizations: new FormControl(savedCandidate.changingOrganizations, Validators.required),
        employeeAppraisals: new FormControl(savedCandidate.employeeAppraisals),
        preferedIndustries: this.formBuilder.array(savedCandidate.expand.preferedIndustries?.map(industry => this.formBuilder.control(industry)) ?? []),
        languages: this.formBuilder.array(savedCandidate.expand.languageLevels?.map(languageLevel => new FormGroup({
          language: new FormControl(languageLevel.expand.language),
          level: new FormControl(languageLevel.level, Validators.required)
        }))),
        locatedRemote: new FormControl(savedCandidate.locatedRemote),
        locatedOnsite: new FormControl(savedCandidate.locatedOnsite),
        locatedCanTravel: new FormControl(savedCandidate.locatedCanTravel),
        locations: this.formBuilder.array(savedCandidate.expand.preferedLocations?.map(location => this.formBuilder.control(location)) ?? []),
        manageDirectLevel: new FormControl(savedCandidate.manageDirectLevel, Validators.required),
        manageIndirectLevel: new FormControl(savedCandidate.manageIndirectLevel, Validators.required),
        myNeedsIndicator1: new FormControl(savedCandidate.myNeedsIndicator1, Validators.required),
        myNeedsIndicator2: new FormControl(savedCandidate.myNeedsIndicator2, Validators.required),
        contactEmail: new FormControl(savedCandidate.contactEmail, Validators.required),
        phoneNumber: new FormControl(savedCandidate.phoneNumber, Validators.required),
        orgPhaseScaleup: new FormControl(savedCandidate.orgPhaseScaleup),
        orgPhaseStable: new FormControl(savedCandidate.orgPhaseStable),
        orgPhaseStartup: new FormControl(savedCandidate.orgPhaseStartup),
        orgSize: new FormControl(savedCandidate.orgSize, Validators.required),
        ownCompany: new FormControl(savedCandidate.ownCompany, Validators.required),
        positions: this.formBuilder.array(savedCandidate.expand.positions?.map(position => new FormGroup({
          role: new FormControl(position.expand.role),
          years: new FormControl(position.years, Validators.required),
          companyName: new FormControl(position.companyName, Validators.required)
        })) ?? []),
        preferInterimCoverDuringRecruitment: new FormControl(savedCandidate.preferInterimCoverDuringRecruitment),
        preferInterimCoverDuringSickleave: new FormControl(savedCandidate.preferInterimCoverDuringSickleave),
        preferInterimLeadChange: new FormControl(savedCandidate.preferInterimLeadChange),
        salaryNegotiations: new FormControl(savedCandidate.salaryNegotiations),
        valueAsLeader: new FormControl(savedCandidate.valueAsLeader, Validators.required),
        valueContext: new FormControl(savedCandidate.valueContext),
        valuePerspective: new FormControl(savedCandidate.valuePerspective),
        valueSelfLeadership: new FormControl(savedCandidate.valueSelfLeadership),
        valueSolutions: new FormControl(savedCandidate.valueSolutions),
        wantedInterimRoles: this.formBuilder.array(savedCandidate.expand.wantedInterimRoles?.map(wantedInterimRole => this.formBuilder.control(wantedInterimRole)) ?? [])
      });
    }).catch(e => console.warn('No candidate created yet'));
  }

  async save() {
    if (this.registrationForm.valid) {

      let bodyParams = {
        user: this.pb.instance().authStore.model!['id'],
        locatedRemote: this.registrationForm.get("locatedRemote")?.getRawValue(),
        locatedOnsite: this.registrationForm.get("locatedOnsite")?.getRawValue(),
        locatedCanTravel: this.registrationForm.get("locatedCanTravel")?.getRawValue(),
        availability: this.registrationForm.get("availability")?.getRawValue(),
        availableFrom: this.registrationForm.get("availability")?.getRawValue() === 'LATER' ? this.ngbDateToDate(NgbDate.from(this.registrationForm.get("availableFrom")?.getRawValue())) : '',
        ownCompany: this.registrationForm.get("ownCompany")?.getRawValue(),
        assignmentType: this.registrationForm.get("assignmentType")?.getRawValue(),
        preferedLocations: (this.registrationForm.get("locations") as FormArray).controls.map(control => control.getRawValue().id),
        preferedIndustries: (this.registrationForm.get("preferedIndustries") as FormArray).controls.map(control => control.getRawValue().id),
        budgetLevels: this.registrationForm.get("budgetLevels")?.getRawValue(),
        employeeAppraisals: this.registrationForm.get("employeeAppraisals")?.getRawValue(),
        salaryNegotiations: this.registrationForm.get("salaryNegotiations")?.getRawValue(),
        manageDirectLevel: this.registrationForm.get("manageDirectLevel")?.getRawValue(),
        manageIndirectLevel: this.registrationForm.get("manageIndirectLevel")?.getRawValue(),
        orgPhaseStartup: this.registrationForm.get("orgPhaseStartup")?.getRawValue(),
        orgPhaseScaleup: this.registrationForm.get("orgPhaseScaleup")?.getRawValue(),
        orgPhaseStable: this.registrationForm.get("orgPhaseStable")?.getRawValue(),
        orgSize: this.registrationForm.get("orgSize")?.getRawValue(),
        preferInterimCoverDuringRecruitment: this.registrationForm.get("preferInterimCoverDuringRecruitment")?.getRawValue(),
        preferInterimCoverDuringSickleave: this.registrationForm.get("preferInterimCoverDuringSickleave")?.getRawValue(),
        preferInterimLeadChange: this.registrationForm.get("preferInterimLeadChange")?.getRawValue(),
        myNeedsIndicator1: this.registrationForm.get("myNeedsIndicator1")?.getRawValue(),
        myNeedsIndicator2: this.registrationForm.get("myNeedsIndicator2")?.getRawValue(),
        contactEmail: this.registrationForm.get("contactEmail")?.getRawValue(),
        phoneNumber: this.registrationForm.get("phoneNumber")?.getRawValue(),
        valueAsLeader: this.registrationForm.get("valueAsLeader")?.getRawValue(),
        changingOrganizations: this.registrationForm.get("changingOrganizations")?.getRawValue(),
        valueContext: this.registrationForm.get("valueContext")?.getRawValue(),
        valuePerspective: this.registrationForm.get("valuePerspective")?.getRawValue(),
        valueSolutions: this.registrationForm.get("valueSolutions")?.getRawValue(),
        valueSelfLeadership: this.registrationForm.get("valueSelfLeadership")?.getRawValue(),
        wantedInterimRoles: (this.registrationForm.get("wantedInterimRoles") as FormArray).controls.map(control => control.getRawValue().id)
      };

      if (this.candidateId) {
        await this.pb.instance().collection<Candidate>("candidate").update(this.candidateId, bodyParams).then(
          updatedCandidate => console.trace('Updated candidate')
        );
      } else {
        await this.pb.instance().collection<Candidate>("candidate").create(bodyParams).then(newCandidate => {
            this.candidateId = newCandidate.id;
          }
        );
      }
      await this.saveLanguages(this.candidateId, (this.registrationForm.get("languages") as FormArray).controls.map(control => control as FormGroup));
      await this.savePositions(this.candidateId, (this.registrationForm.get("positions") as FormArray).controls.map(control => control as FormGroup));
      this.profileService.loadCandidate();
      await this.router.navigate(this.wizard ? ['/profil/bekrafta'] : ['/profil'], { queryParams: { wizard: this.wizard } });
    }
  }

  async saveLanguages(candidateId: string, languageForm: FormGroup[]) {
    let languageLevels: string[] = [];
    for (let languageGroup of languageForm) {
      await this.pb.instance().collection('candidateLanguageLevel').create({
        candidate: candidateId,
        language: (languageGroup.get('language')?.getRawValue() as Language).id,
        level: languageGroup.get('level')?.getRawValue()
      }).then(newLanguageLevel => {
        languageLevels.push(newLanguageLevel.id);
      });
    }
    await this.pb.instance().collection('candidate').update(candidateId, { languageLevels: languageLevels });
  }

  async savePositions(candidateId: string, positionForm: FormGroup[]) {
    let positions: string[] = [];
    for (let positionGroup of positionForm) {
      await this.pb.instance().collection('position').create({
        role: (positionGroup.get('role')?.getRawValue() as Role).id,
        years: positionGroup.get('years')?.getRawValue(),
        companyName: positionGroup.get('companyName')?.getRawValue()
      }).then(newPosition => {
        positions.push(newPosition.id);
      });
    }
    await this.pb.instance().collection('candidate').update(candidateId, { positions: positions });
  }

  onSelectLocation($event: any, input: any) {
    $event.preventDefault();
    input.value = '';
    this.locations.push(this.formBuilder.control($event.item));
  }

  onSelectWantedInterimRole($event: any, input: any) {
    $event.preventDefault();
    input.value = '';
    this.wantedInterimRoles.push(this.formBuilder.control($event.item));
  }

  onSelectRole($event: any, input: any) {
    $event.preventDefault();
    input.value = '';
    this.positions.push(new FormGroup({
      role: new FormControl($event.item),
      years: new FormControl('', Validators.required),
      companyName: new FormControl('', Validators.required)
    }));
  }

  onSelectIndustry($event: any, input: any) {
    $event.preventDefault();
    input.value = '';
    this.industries.push(this.formBuilder.control($event.item));
  }

  renderLanguageLevel(level: number) {
    switch (level) {
      case 0:
        return "Ingen kunskap";
      case 1:
        return "Elementära kunskaper"
      case 2:
        return "Goda kunskaper"
      case 3:
        return "Flytande"
    }
    return "";
  }

  removeLocation(index: number) {
    this.locations.removeAt(index);
  }

  removeIndustry(index: number) {
    this.industries.removeAt(index);
  }

  removeWantedInterimRole(index: number) {
    this.wantedInterimRoles.removeAt(index);
  }

  removePosition(index: number) {
    this.positions.removeAt(index);
  }

  ngbDateToDate(rawValue: any) {
    if (rawValue)
      return new Date(rawValue.year, rawValue.month - 1, rawValue.day, 12);
    return null;
  }
}
