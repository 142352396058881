@if (user()?.name) {
  <div class="profile-header">
    @if (candidate()?.status !== 'READY') {
      <div>
        <ol aria-label="Registreringssteg"
            class="card-bodyass list-unstyled step-circles"
            role="tablist">
          <li [attr.aria-selected]="progress()!.value === 1" [ngClass]="{'current_step': progress()!.value === 1}"
              class="step-item"
              role="tab">
            <div class="step-item-circle">
              <span class="visually-hidden">Steg 1</span>
              <span class="step-number">1</span>
            </div>
          </li>
          <li [attr.aria-selected]="progress()!.value === 2" [ngClass]="{'current_step': progress()!.value === 2}"
              class="step-item"
              role="tab">
            <div class="step-item-circle">
              <span class="visually-hidden">Steg 2</span>
              <span class="step-number">2</span>
            </div>
          </li>
          <li [attr.aria-selected]="progress()!.value === 3" [ngClass]="{'current_step': progress()!.value === 3}"
              class="step-item"
              role="tab">
            <div class="step-item-circle">
              <span class="visually-hidden">Steg 3</span>
              <span class="step-number">3</span>
            </div>
          </li>
          <li [attr.aria-selected]="progress()!.value === 4" [ngClass]="{'current_step': progress()!.value === 4}"
              class="step-item"
              role="tab">
            <div class="step-item-circle">
              <span class="visually-hidden">Steg 4: Klar</span>
              <span class="step-done">Klar</span>
            </div>
          </li>
        </ol>
        <div class="progress-label">{{ progress()!.label }}</div>
      </div>
    }
    @if (candidate()?.status === 'READY') {
      <div>
        <div *ngIf="progress()!.value === 3" class="profile-header__title h2">Ditt CV</div>
        <div *ngIf="progress()!.value !== 3" class="profile-header__title h2">Din Profil</div>
      </div>
    }
  </div>
}
<div class="row app-content__frame-inner">
  <div class="main-col col-12 col-md-7 col-lg-8">
    <router-outlet></router-outlet>
  </div>
  <div class="col-12 col-md-5 col-lg-4 pt-5 pt-md-0">
    <app-support-info></app-support-info>
  </div>
</div>
