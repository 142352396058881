import { Component, computed, Inject, Input, LOCALE_ID } from '@angular/core';
import { environment } from "../../../environments/environment";
import { PocketbaseService } from "../../shared/pocketbase.service";
import { ProfileService } from "../profile.service";
import { ToastrService } from "ngx-toastr";
import { Clipboard, ClipboardModule } from "@angular/cdk/clipboard";
import { Title } from "@angular/platform-browser";
import { Router, RouterLink } from "@angular/router";
import { ConfirmPresentationComponent } from "../../confirm/confirm-presentation/confirm-presentation.component";


@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [
    ClipboardModule,
    ConfirmPresentationComponent,
    RouterLink
  ],
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss'
})
export class ConfirmationComponent {
  window = window;
  candidate;
  user;
  @Input()
  wizard = false;

  cvUrl = computed(() => this.candidate()?.cv ? `${environment.pocketbaseUrl}/api/files/candidate/${this.candidate()?.id}/${this.candidate()?.cv}` : "");

  constructor(private pb: PocketbaseService,
              private profileService: ProfileService,
              private toastr: ToastrService,
              private router: Router,
              private clipboard: Clipboard, @Inject(LOCALE_ID)
              private locale: string, private title: Title) {
    this.candidate = this.profileService.$candidate;
    this.user = this.profileService.$user;
    title.setTitle("Bekräfta din profil");
  }

  toastrCopiedLink() {
    this.toastr.success("Länken är kopierad. Nu kan du klistra in den i ditt meddelande till de som ska bekräfta din profil.", "Länk kopierad");
  }

  linkedinUrl() {
    return `${window.location.origin}/bekrafta/${this.candidate()?.id}`;
  }
}
