/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import * as Sentry from "@sentry/angular-ivy";
import { version } from './environments/version';
import { config } from "./app/app.config.browser";

Sentry.init({
  release: "ml-app@" + version,
  dsn: "https://90c893abaec00c45788fd70939d3100b@o4506823222231040.ingest.sentry.io/4506823225245696",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://app.modernaledare.se"],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
bootstrapApplication(AppComponent, config)
  .catch((err) => console.error(err));
