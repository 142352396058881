<header class="header fixed-top" id="app-header">
  <div class="header-top container-xl">
    <a class="navbar-brand" href="#">
      <img alt="Moderna Ledare" src="assets/images/logo_moderna-ledare_light.svg"/>
    </a>
  </div>
  <div class="header-nav navbar navbar-expand-md" data-bs-theme="dark">
    <button
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarNav"
    aria-expanded="false"
    aria-label="Visa navigation"
    class="navbar-toggler border-0"
    type="button"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
    <nav class="container-xl header-nav">
      <div [ngbCollapse]="isCollapsed" class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav navbar-nav--dividers my-3 my-md-0">
          <li class="nav-item">
            <a class="nav-link" href="https://modernaledare.se">Moderna Ledares
              hemsida</a>
          </li>
          @if (!candidate() || !(candidate()?.status === 'READY')) {
            <li class="nav-item">
              <a class="nav-link active" routerLink="/profil/introduktion">Skapa profil</a>
            </li>
          } @else {
            <li class="nav-item">
              <a class="nav-link" routerLink="/profil" routerLinkActive="active">Din profil</a>
            </li>
          }
        </ul>
      </div>
      @if (user()?.id) {
        <ul class="navbar-nav ms-auto">
          <li class="nav-item" display="dynamic" ngbDropdown placement="bottom-end">
            <a class="nav-link nav-link--user " id="navbarUserDropdown" ngbDropdownToggle role="button"
               tabindex="0">{{ user()?.name }}</a>
            <ul aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-end dropdown-menu__user"
                ngbDropdownMenu>
              <li class="nav-item">
                <div class="user-card">
                  @if (user()?.avatarUrl) {
                    <img class="user-card__portrait" src="{{user()?.avatarUrl}}"/>
                  } @else {
                    <div class="user-card__portrait rounded-circle bg-primary" aria-hidden="true">{{ user()?.name?.charAt(0) }}</div>
                  }
                  <div class="user-card__name">{{ user()?.name }}</div>
                </div>
              </li>
              <li class="nav-item">
                <hr class="dropdown-divider"/>
              </li>
              <li class="nav-item" *ngIf="candidate()?.status">
                <a ngbDropdownItem routerLink="profil/registrera-dig">Uppdatera profil</a>
              </li>
              <li class="nav-item" *ngIf="candidate()?.status">
                <a ngbDropdownItem routerLink="profil/cv">Uppdatera CV</a>
              </li>
              <li class="nav-item">
                <a ngbDropdownItem routerLink="profil/radera">Radera profil</a>
              </li>
              <li>
                <hr class="dropdown-divider"/>
              </li>
              <a (click)="logout()" class="hover-pointer" ngbDropdownItem>Logga ut</a>
            </ul>
          </li>
        </ul>
      }
    </nav>
  </div>
</header>
