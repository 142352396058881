import { Component, Input } from '@angular/core';

import { Candidate } from "../../model/candidate";
import { User } from "../../model/user";

@Component({
  selector: 'app-validation-presentation',
  standalone: true,
  imports: [],
  templateUrl: './confirm-presentation.component.html',
  styleUrl: './confirm-presentation.component.scss'
})
export class ConfirmPresentationComponent {
  @Input() candidateToConfirm!: Candidate | undefined;
  @Input() userToConfirm!: User | undefined;
  @Input() validateFn!: Function;
  @Input() confirmationEnabled = true;
}
