import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, PLATFORM_ID } from '@angular/core';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter,
  Router,
  withComponentInputBinding,
  withInMemoryScrolling
} from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch } from "@angular/common/http";
import { isPlatformServer } from "@angular/common";
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '../environments/environment';

function provideSentryProviders(platformId: object): unknown[] {
  if (isPlatformServer(platformId) || !environment.enableSentry) {
    return [];
  }
  return [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ];
}

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, inMemoryScrollingFeature, withComponentInputBinding()),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideSentryProviders(PLATFORM_ID)
  ]
};
