import { Component, OnInit, signal, WritableSignal } from '@angular/core';

import { PocketbaseService } from "../../shared/pocketbase.service";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { Candidate } from "../../model/candidate";
import { NgbProgressbar } from "@ng-bootstrap/ng-bootstrap";
import { User } from "../../model/user";
import { ProfileService } from "../../profile/profile.service";
import { ConfirmPresentationComponent } from "../confirm-presentation/confirm-presentation.component";
import { SupportInfoComponent } from "../../shared/support-info/support-info.component";

@Component({
  selector: 'app-confirm-candidate',
  standalone: true,
  imports: [NgbProgressbar, ConfirmPresentationComponent, RouterLink, SupportInfoComponent],
  templateUrl: './confirm-candidate.component.html',
  styleUrl: './confirm-candidate.component.scss'
})
export class ConfirmCandidateComponent implements OnInit {
  candidateId!: string | null;
  user!: WritableSignal<User | undefined>;
  candidateToConfirm = signal<Candidate | undefined>(undefined);

  constructor(private pb: PocketbaseService, private route: ActivatedRoute, private router: Router, private profileService: ProfileService) {
  }

  ngOnInit(): void {
    this.candidateId = this.route.snapshot.paramMap.get('candidateId');
    if (this.candidateId) {
      this.user = this.profileService.$user;
      this.pb.instance().collection('candidate').getOne<Candidate>(this.candidateId, { expand: 'user,positions,positions.role' }).then((result) => {
        this.candidateToConfirm.set(result);
      }).catch(error => {
        console.log(error);
      });
    }
  }

  confirmCandidate = (position:any) => {
    this.pb.instance().collection('validation').create({
      validated: this.candidateId,
      validatedBy: this.pb.instance().authStore.model!['id'],
      validatedPosition: position.id
    }).then(result => {
      this.router.navigate(['bekraftad']);
    }).catch(error => {
    });
  }
}
