import { Component, OnInit } from '@angular/core';

import { HttpClient } from "@angular/common/http";
import { PocketbaseService } from "../shared/pocketbase.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProfileService } from "../profile/profile.service";
import { User } from "../model/user";
import { SupportInfoComponent } from "../shared/support-info/support-info.component";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [SupportInfoComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  returnUrl: string | null = '';
  loginHeading: string = '';
  toConfirmCandidate: boolean = false;

  constructor(private http: HttpClient, private pb: PocketbaseService, private route: ActivatedRoute, private router: Router, private profileService: ProfileService) {
  }
  login() {
    let w = window.open();
    this.pb.instance().collection<User>('users').authWithOAuth2({ 
      provider: 'oidc',
      urlCallback: (url) => {
        if (w) {
          w.location.href = url;
        }
      }
    }).then(authenticationResult => {
      // console.log(authenticationResult);
      if (authenticationResult.meta) {
        this.pb.instance().collection('users').update(
          authenticationResult.record.id,
          {
            name: authenticationResult.meta["name"],
            avatarUrl: authenticationResult.meta["avatarUrl"]
          });
      }
      this.profileService.loadCandidate();
      if (this.returnUrl) {
        this.router.navigate([this.returnUrl]);
      } else {
        this.router.navigate(['']);
      }
      
    }).catch(error => {
      console.error(error);
    });
    // this.pb.instance().collection<User>('users').authWithOAuth2({ provider: 'oidc' })
    // .then(authenticationResult => {
    //     if (authenticationResult.meta) {
    //       this.pb.instance().collection('users').update(
    //         authenticationResult.record.id,
    //         {
    //           name: authenticationResult.meta["name"],
    //           avatarUrl: authenticationResult.meta["avatarUrl"]
    //         });
    //     }
    //     this.profileService.loadCandidate();
    //     if (this.returnUrl) {
    //       this.router.navigate([this.returnUrl]);
    //     } else {
    //       this.router.navigate(['']);
    //     }
    //   });
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
    this.route.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'] || '';
      this.setLoginMessage();
    });
  }

  setLoginMessage(): void {
    if (this.returnUrl?.includes('/bekrafta')) {
      this.loginHeading = 'Logga in för att bekräfta kandidat';
      this.toConfirmCandidate = true;
    } else {
      this.loginHeading = 'Logga in';
    }
  }
}
