import { User } from "./user";

export class Candidate {
  id!: string;
  availableFrom!: string;
  located!: any;
  locatedRemote!: boolean;
  locatedOnsite!: boolean;
  locatedCanTravel!: boolean;
  availability!: string;
  ownCompany!: string;
  assignmentType!: string;
  budgetLevels!: string;
  employeeAppraisals!: boolean;
  salaryNegotiations!: boolean;
  manageDirectLevel!: string;
  manageIndirectLevel!: string;
  orgPhaseStartup!: boolean;
  orgPhaseScaleup!: boolean;
  orgPhaseStable!: boolean;
  orgSize!: string;
  preferInterimCoverDuringRecruitment!: boolean;
  preferInterimCoverDuringSickleave!: boolean;
  preferInterimLeadChange!: boolean;
  myNeedsIndicator1!: string;
  myNeedsIndicator2!: string;
  valueAsLeader!: string;
  changingOrganizations!: string;
  valueContext!: number;
  valuePerspective!: number;
  valueSolutions!: number;
  valueSelfLeadership!: number;
  status!: string;
  contactEmail!: string;
  phoneNumber!: string;
  cv!: string;
  interestedIn360!: string;
  confirmed!: boolean;
  welcomeEmailSent!: string;
  expand!: {
    preferedIndustries: [any];
    user: User;
    preferedLocations: [any];
    languageLevels: [any];
    positions: [any];
    wantedInterimRoles: [any]
  }; // This is where Pocketbase expands relations;
}

export const Availability = [
  { key: "NOW", text: "Direkt" },
  { key: "LATER", text: "Från och med" }
];
export const Located = [
  { key: "REMOTE", text: "På distans" },
  { key: "ONSITE", text: "På plats" }
];

export const OwnCompany = [
  { key: "NO", text: "Nej" },
  { key: "COULD_START", text: "Nej, men kan starta" },
  { key: "YES", text: "Ja" }
];

// make this into objects with key and text
export const AssignmentType = [
  { key: "INTERIM", text: "Interim" },
  { key: "INTERIM_CONTINUED", text: "Interim med eventuell fortsatt anställning" }
];

export const budgetLevels = [
  { key: "NONE", text: "Har inte haft budgetansvar" },
  { key: "TO_50K", text: "Upp till 50 000 kr" },
  { key: "TO_100K", text: "Upp till 100 000 kr" },
  { key: "TO_300K", text: "Upp till 300 000 kr" },
  { key: "TO_500K", text: "Upp till 500 000 kr" },
  { key: "TO_1M", text: "Upp till 1 miljon" },
  { key: "TO_3M", text: "Upp till 3 miljoner" },
  { key: "TO_5M", text: "Upp till 5 miljoner" },
  { key: "TO_10M", text: "Upp till 10 miljoner" },
  { key: "TO_20M", text: "Upp till 20 miljoner" },
  { key: "TO_50M", text: "Upp till 50 miljoner" },
  { key: "ABOVE_50M", text: "Över 50 miljoner" }
];

export const manageDirectLevel = [
  { key: "0_10", text: "0-10" },
  { key: "10_20", text: "10 - 20" },
  { key: "20_30", text: "20 - 30" },
  { key: "30_50", text: "30 - 50" },
  { key: "ABOVE_50", text: "Fler än 50" }
];

export const manageIndirectLevel = [
  { key: "1_50", text: "1 - 50" },
  { key: "50_100", text: "50 - 100" },
  { key: "100_500", text: "100 - 500" },
  { key: "500_1K", text: "500 - 1000" },
  { key: "1K_5K", text: "1000 - 5000" },
  { key: "5K_10K", text: "5000 - 10 000" },
  { key: "10K_50K", text: "10 000 - 50 000" },
  { key: "ABOVE_50K", text: "Fler än 50 000" }
];

export const OrgSizes = [
  { key: "ANY", text: "Spelar ingen roll" },
  { key: "SMALL", text: "Liten" },
  { key: "MEDIUM", text: "Medel" },
  { key: "LARGE", text: "Stor" }
];


export const MyNeedsIndicators = [
  { key: "GOAL", text: "Att driva utveckling och nå mål" },
  { key: "RISK_ELIMINATION", text: "Riskeliminering" },
  { key: "EFFECTIVE", text: "Effektivisera" },
  { key: "TEAM", text: "Bygga välfungerande team" },
  { key: "HOLISTIC", text: "Bidra till helhet och en röd tråd" },
  { key: "QUALITY", text: "Bygga kvalitet" }
];

export const ValuesAsLeader = [
  {
    key: "DETAILS",
    text: "Jag värderar att ha god detaljkunskap och funktionell expertis som ledare",
    short: "Detaljer"
  },
  { key: "RESULTS", text: "Jag värderar att vara strategiskt resultatorienterad som ledare", short: "Resultat" },
  { key: "VISION", text: "Jag värderar att vara visionär med facilitativ orientering som ledare", short: "Vision" }
];

export const ChangingOrganizations = [
  {
    key: "STEPS",
    text: "Jag tar förändringen stegvis för att inte skapa för mycket “brus” i organisationen",
    short: "Stegvis"
  },
  {
    key: "BUY_IN",
    text: "Jag arbetar mycket med Buy-in i organisationen för att få med dem på mina idéer",
    short: "Buy-in"
  },
  {
    key: "CULTURE",
    text: "Jag arbetar mycket med att utveckla kulturen i organisatoriska förändringar",
    short: "Kultur"
  }
]


