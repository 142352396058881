<div class="row app-content__frame-inner">
  <div class="main-col col-12 col-md-7 col-lg-8">
    <div class="main-col__content">
      <div class="mb-5">
        <h1>Tack!</h1>
        <p>Tack för att du hjälper till att validera Moderna Ledare.</p>
      </div>
      <h2 class="border--bottom">Är du också en modern ledare?</h2>
      <p>Läs mer på hemsidan och <a [routerLink]="'/profil/registrera-dig'">registrera en profil</a>.</p>
    </div>
  </div>
  <div class="col-12 col-md-5 col-lg-4 pt-5 pt-md-0">
    <app-support-info></app-support-info>
  </div>
</div>
