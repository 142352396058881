import { Component } from '@angular/core';

import { Router, RouterLink } from "@angular/router";
import { PocketbaseService } from "../../shared/pocketbase.service";
import { ProfileService } from "../profile.service";

@Component({
  selector: 'app-delete-profile',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './delete-profile.component.html',
  styleUrl: './delete-profile.component.scss'
})
export class DeleteProfileComponent {

  constructor(private pb: PocketbaseService, private profileService: ProfileService, private router: Router) {
  }

  async deleteProfile() {
    let candidateId = this.profileService.$candidate()?.id ?? "";
    if (candidateId)
      await this.pb.instance().collection("candidate").delete(candidateId).then(() => {
        this.profileService.$candidate.set(undefined);
      });
    await this.pb.instance().collection("users").delete(this.pb.instance().authStore.model!['id']).then(() => {
      this.profileService.$user.set(undefined);
    });
    this.router.navigate(['/profil-raderad']);
  }
}
