<div class="main-col__content">
  <h1>Vad roligt att du vill bli en Modern Ledare!</h1>
  <p>Vi är tacksamma för att du tar dig tid att skapa en profil hos oss. För att vi ska kunna matcha kunduppdragen mot
    din profil på bästa sätt har vi identifierat den information som är avgörande för att det ska bli så bra som
    möjligt.</p>
  <strong>Du kan uppdatera och radera din profil</strong>
  <p>Informationen finns bara i vår databas så länge du är aktiv hos oss. När du har fyllt i profilen så kommer du
    till din egen profilsida. Där kommer du själv att kunna radera din profil om du vill det, och du kan alltid
    uppdatera din information själv.</p>
  <strong>Koppling till LinkedIn för hög kvalitet</strong>
  <p>Vi har gjort en koppling till LinkedIn, det är för att vi ska förenkla för dig att få tillgång till din profil
    här hos oss, samt att säkerställa en hög kvalitet.</p>
  <div class="text-center pt-4">
    <button [queryParams]="{wizard:true}" class="btn btn-lg btn-primary" routerLink="/profil/registrera-dig">
      Skapa profil
    </button>
  </div>
</div>
