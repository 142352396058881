<h1>Ladda upp CV</h1>
<p>Ladda upp ditt CV för att vi snabbt ska ha det tillgängligt.</p>
<div class="card mb-3">
  <div class="card-header">Uppladdat CV</div>
    <div class="card-body">
      @if (candidate()?.cv) {
        <p>
          <i class="bi-filetype-doc m-1"></i> <a href="{{cvUrl()}}" target="_blank">Ditt senaste CV: {{ this.candidate()?.cv }}</a>
        </p>
      } @else {
        <em>Du har inte laddat upp något.</em>
      }
    </div>
</div>

<div class="card bg-light">
  <div class="card-body">
    <h5 class="card-title mb-3">Ladda upp CV</h5>
    <div class="form-group">
      <input (change)="uploadCV($event)"
            class="form-control"
            id="cvfile"
            type="file"
            aria-describedby="cvHelpBlock">
      <div id="cvHelpBlock" class="form-text">Endast CV i Word-format accepteras.</div>
    </div>
  </div>
</div>

<div class="mt-3 d-flex justify-content-between">
  @if (wizard) {
    <button [routerLink]="'/profil/bekrafta'" [queryParams]="{wizard:true}" class="btn btn-primary btn-lg"
            type="submit">
      Tillbaka
    </button>
  }
  <button [disabled]="candidate()?.status !== 'READY'" [routerLink]="''" class="btn btn-primary btn-lg"
          type="submit">
    {{ saveButtonText() }}
  </button>
</div>

