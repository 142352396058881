import { Component, computed, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterLink, RouterOutlet } from "@angular/router";
import { PocketbaseService } from "../../shared/pocketbase.service";
import { LocalStorageService } from "../../shared/local-storage.service";
import { NgbProgressbar } from "@ng-bootstrap/ng-bootstrap";
import { ProfileService } from "../profile.service";
import { SupportInfoComponent } from "../../shared/support-info/support-info.component";
import { User } from "../../model/user";
import { Candidate } from "../../model/candidate";

@Component({
  selector: 'app-profile-frame',
  standalone: true,
  imports: [CommonModule, RouterOutlet, NgbProgressbar, SupportInfoComponent, RouterLink],
  templateUrl: './profile-frame.component.html',
  styleUrl: './profile-frame.component.scss'
})
export class ProfileFrameComponent implements OnInit {

  user!: WritableSignal<User | undefined>;
  candidate!: WritableSignal<Candidate | undefined>;
  private path: WritableSignal<string> = signal("");
  progress = computed((): Status => {
    let path = this.path().split('?')[0];
    switch (path) {
      case "/profil/registrera-dig":
        return { value: 1, label: "Första steget mot att bli en modern ledare är att skapa en profil." };
      case "/profil/bekrafta":
        return { value: 2, label: "Skicka länken till minst två kontakter på LinkedIn för att bli validerad." };
      case "/profil/cv":
        return { value: 3, label: "Ladda upp ditt CV så att vi snabbt kan matcha dig mot uppdrag." };
      case "READY":
        return { value: 4, label: "Jippi! Din profil är klar. Nu kan vi kan matcha dig mot uppdrag." };
      default:
        return { value: 0, label: "" }
    }
  });

  constructor(private router: Router, private pb: PocketbaseService, private localStorage: LocalStorageService, private profileService: ProfileService) {
  }

  ngOnInit() {
    this.user = this.profileService.$user;
    this.candidate = this.profileService.$candidate;

    this.path.set(this.router.url);
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.path.set(event.url);
      }
    });
  }

}

interface Status {
  value: number;
  label: string;
}
