<div class="card">
  <div class="card-body">
    <h1>Kandidater</h1>
    @if (result) {
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Namn</th>
          <th scope="col">Placering</th>
          <th scope="col">Branscher</th>
          <th scope="col">Tillgänglig</th>
          <th scope="col">Eget företag</th>
          <th scope="col">Typ av uppdrag</th>
          <th scope="col">Språk</th>
          <th scope="col">Erfarenhet</th>
          <th scope="col">Ansvar</th>
          <th scope="col">Ledning</th>
          <th scope="col">MyNeeds</th>
          <th scope="col">Som ledare</th>
          <th scope="col">Hur viktigt?</th>
        </tr>
        </thead>
        <tbody>
          @for (item of result; track trackById(i, item); let i = $index) {
            <tr>
              <td>
                <img class="portrait" src="{{item.expand.user.avatarUrl}}">
                {{ item.expand.user.name }}
                <a href="mailto:{{ item.expand.user.email }}"><i class="bi-envelope"></i></a></td>
              <td>
                {{ keyToText(Located, item.located) }}
                @for (loc of item.expand.preferedLocations; track trackById(i, loc); let i = $index) {
                  <div>{{ loc.name }}</div>
                }
              </td>
              <td>
                @for (loc of item.expand.preferedIndustries; track trackById(i, loc); let i = $index) {
                  <div>{{ loc.name }}</div>
                }
              </td>
              <td>{{ keyToText(Availability, item.availability) }}<br>{{ item.availableFrom.slice(0, 10) }}</td>
              <td>{{ keyToText(OwnCompany, item.ownCompany) }}</td>
              <td>{{ keyToText(AssignmentType, item.assignmentType) }}</td>
              <td>
                @for (loc of item.expand.languageLevels; track trackById(i, loc); let i = $index) {
                  <div>
                    {{ loc.expand.language.name }}: {{ loc.level }}
                  </div>
                }
              </td>
              <td>
                @for (loc of item.expand.positions; track trackById(i, loc); let i = $index) {
                  <div>
                    {{ loc.expand.role.name }}, {{ loc.companyName }}: {{ loc.years }} år
                  </div>
                }
              </td>
              <td>
                <div>Budget: {{ keyToText(BudgetLevels, item.budgetLevels) }}</div>
                <div>Medarbetarsamtal: {{ item.employeeAppraisals ? "Ja" : "Nej" }}</div>
                <div>Lönesamtal: {{ item.salaryNegotiations ? "Ja" : "Nej" }}</div>
              </td>
              <td>
                <div>Direkt: {{ keyToText(ManageDirectLevel, item.manageDirectLevel) }}</div>
                <div>Indirekt: {{ keyToText(ManageIndirectLevel, item.manageIndirectLevel) }}</div>
              </td>
              <td>
                <div>{{ keyToText(MyNeedsIndicators, item.myNeedsIndicator1) }}</div>
                <div>{{ keyToText(MyNeedsIndicators, item.myNeedsIndicator2) }}</div>
              </td>
              <td>
                <div>{{ keyToShortText(ValuesAsLeader, item.valueAsLeader) }}</div>
                <div>{{ keyToShortText(ChangingOrganizations, item.changingOrganizations) }}</div>
              </td>
              <td>
                <div class="col">Kontext: {{ item.valueContext }}</div>
                <div class="col">Perspektiv: {{ item.valuePerspective }}</div>
                <div class="col">Lösning: {{ item.valueSolutions }}</div>
                <div class="col">Sjävledarskap: {{ item.valueSelfLeadership }}</div>
              </td>
            </tr>
          }
        </tbody>
      </table>
    }
  </div>
</div>
