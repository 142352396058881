@if (candidate()?.id) {
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="card bg-light">
        <div class="card-body">
          <h2 class="border--bottom">Uppdatera din profil</h2>
          <p>För att vi ska kunna matcha dig mot relevanta uppdrag är det viktigt att du håller din profil uppdaterad. De som har tillgänglighet kommer att prioriteras.</p>
          <p>
            Din tillgänglighet: <strong>{{ availability() }}</strong>
          </p>
          <div class="mt-3 d-grid">
            <button class="btn btn-primary" routerLink="registrera-dig">Uppdatera din profil</button>
          </div>
        </div>
      </div>
      <div class="card bg-light mt-3">
        <div class="card-body">
          <h2 class="border--bottom">Vill du validera din ledarskapsförmåga?</h2>
          <p>Hos oss kan du validera din ledarskapsförmåga genom en Leadership Agility 360 som identifierar din vertikala utveckling. De med validerad ledarskapsförmåga har lättare att få bra interimsuppdrag hos oss då våra kunder tycker att den faktiska förmågan är viktig.
          </p>
          <p>
            <img class="float-end" src="/assets/images/leadership-agility-vertikal-utveckling.png"
                 style="width: 40%; margin-left: 1em"/>
            <strong>Utveckla ditt ledarskap</strong><br>
            Med hjälp av denna 360 kan du få syn på vad du kan utveckla som ledare, och med hjälp av coaching från våra ledarskapscoacher kan du lättare utveckla dessa förmågor.
          </p>
          <p><a href="https://modernaledare.se/tjanster/validering-genom-leadership-agility-360/">Läs mer om Leadership Agility och hur vi gör en 360</a></p>
          <div class="mt-3 d-grid">
            @if (!candidate()?.interestedIn360) {
              <button (click)="interestedIn360()"
                      class="btn btn-primary" href="https://modernaledare.se/kontakta-oss/">
                Jag vill gärna veta mer
              </button>
            }
            <div [class.fw-bold]="candidate()?.interestedIn360 === 'INTERESTED'" class="text-center mt-2">Vi kontaktar dig för en kort konsultation
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      @if (!candidate()?.confirmed) {
        <div class="card bg-light">
          <div class="card-body">
            <h2 class="border--bottom">Få din profil bekräftad</h2>
            <p class="card-text">Du har inte blivit bekräftad av några LinkedIn-kontakter än. Sista steget för att du ska kunna bli
              matchad mot uppdrag är att två av dina kontakter på Linkedin bekräftar minst en av dina roller.</p>
            <p class="card-text"><strong>Skicka nedanstående länk till de du vill ska bekräfta dina tidigare roller.</strong></p>
          </div>
          <div class="card-footer">
            <div class="mt-2 confirm-link">
              {{ linkedinUrl() }}
            </div>
            <div class="d-grid my-2">
              <button (click)="toastrCopiedLink()" [cdkCopyToClipboard]="linkedinUrl()"
                    class="btn btn-success btn-block"><i
              class="bi-copy"></i> Kopiera länk
              </button>
            </div>
          </div>
        </div>
      }
      <div [class.mt-3]="!candidate()?.confirmed" class="card bg-light">
        <div class="card-body">
          <h2 class="border--bottom">Sök tjänster aktivt <i class="bi-linkedin float-end"></i></h2>
          <p>Följ oss på LinkedIn för att se och söka våra lediga tjänster.</p>
          <div class="mt-3 d-grid">
            <a class="btn btn-primary" href="https://www.linkedin.com/company/moderna-ledare/">Följ oss på LinkedIn</a>
          </div>
        </div>
      </div>
      <div class="card bg-light mt-3">
        <div class="card-body">
          <h2 class="border--bottom">Nätverket Moderna Ledare</h2>
          <p class="card-text">Välkommen till nätverket som en Modern Ledare. Hos oss får du som vill möjlighet att träffa andra ML:are,
            gå utbildningar och få coaching.</p>
          <div class="mt-3 d-grid">
            <a class="btn btn-primary" href="https://modernaledare.se/nyhetsbrev/" target="_blank">
              Registrera dig för vårt Nyhetsbrev
            </a>
          </div>
          <div class="text-center mt-2">Håll dig uppdaterad via vårt nyhetsbrev.</div>
        </div>
      </div>
    </div>
  </div>
}
