<h1 class="mb-2">Registrera dig för att bli en Modern Ledare</h1>
<p>Vi vill att du ska fylla i följande formulär för att vi ska kunna matcha dig med uppdragsgivare.</p>
<form [formGroup]="registrationForm">

  <fieldset>
    <label class="form-label">Hur vill du arbeta?</label>
    <div class="form-check form-switch mb-3">
      <input class="form-check-input" formControlName="locatedRemote" id="locatedRemote"
             type="checkbox">
      <label class="form-check-label" for="locatedRemote">På distans</label>
    </div>
    <div class="form-check form-switch mb-3">
      <input class="form-check-input" formControlName="locatedOnsite" id="locatedOnsite"
             type="checkbox">
      <label class="form-check-label" for="locatedOnsite">På plats</label>
    </div>
    <div class="form-check form-switch mb-3">
      <input class="form-check-input" formControlName="locatedCanTravel" id="locatedCanTravel"
             type="checkbox">
      <label class="form-check-label" for="locatedCanTravel">Jag kan resa om det behövs</label>
    </div>
  </fieldset>

  @if (registrationForm.controls.locatedOnsite.getRawValue()) {
    <fieldset>
      <label class="form-label" for="typeahead-location">Var vill du arbeta?</label>
      <div class="row">
        <div class="col-8">
          <table class="table table-striped table-bordered">
            <tbody>
              @for (location of locations.controls; track location; let i = $index) {
                <tr formArrayName="locations">
                  <td><label for="location-{{ i }}">{{ location.getRawValue().name }}</label></td>
                  <td class="text-end"><i (click)="removeLocation(i)"
                                          class="bi-trash remove-icon-button hover-pointer"></i>
                  </td>
                </tr>
              }
            </tbody>
          </table>
          <input
            #location="ngbTypeahead"
            #locationInput
            (click)="locationClick$.next($any($event).target.value)"
            (focus)="locationFocus$.next($any($event).target.value)"
            (selectItem)="onSelectLocation($event, locationInput)"
            [editable]="false"
            [inputFormatter]="typeaheadFormatter"
            [ngbTypeahead]="searchLocations"
            [resultFormatter]="typeaheadFormatter"
            class="form-control"
            id="typeahead-location"
            placeholder="Börja skriv för fler förslag på platser och områden"
            type="text"
          />
        </div>
        <div class="col-4"></div>
      </div>
    </fieldset>
  }

  <fieldset>
    <div class="row">
      <div class="col-8">
        <label class="form-label" for="typeahead-industry">Har du någon föredragen bransch?</label>
        <table class="table table-striped table-bordered">
          <tbody>
            @for (industry of industries.controls; track industry; let i = $index) {
              <tr formArrayName="preferedIndustries">
                <td><label for="industry-{{ i }}">{{ industry.getRawValue().name }}</label></td>
                <td class="text-end"><i (click)="removeIndustry(i)"
                                        class="bi-trash remove-icon-button hover-pointer"></i>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="col-4"></div>
    </div>
    <div class="row">
      <div class="col-8">
        <input
          #industry="ngbTypeahead"
          #industryInput
          (click)="industryClick$.next($any($event).target.value)"
          (focus)="industryFocus$.next($any($event).target.value)"
          (selectItem)="onSelectIndustry($event, industryInput)"
          [editable]="false"
          [inputFormatter]="typeaheadFormatter"
          [ngbTypeahead]="searchIndustries"
          [resultFormatter]="typeaheadFormatter"
          class="form-control"
          id="typeahead-industry"
          placeholder="Börja skriv för fler förslag på branscher"
          type="text"
        />
      </div>
      <div class="col-4 d-flex align-items-center">
        <a (click)="saveIndustrySuggestion(content)" class="link-dark hover-pointer">Saknar du din bransch?
        </a>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">När är du tillgänglig?</label>
    <div aria-label="Välj när du är tillgänglig" role="group">
      <div class="form-check">
        <input class="form-check-input" formControlName="availability" id="availabilityOption-1" type="radio"
               value="NOW">
        <label class="form-check-label" for="availabilityOption-1">Direkt</label>
      </div>
    </div>
    <div class="form-check">
      <input class="form-check-input" formControlName="availability" id="availabilityOption-2" type="radio"
             value="LATER">
      <label class="form-check-label" for="availabilityOption-2">Från och
        med</label>

      @if (registrationForm.get('availability')?.value === 'LATER') {
        <div class="row col-6">
          <div class="input-group">
            <input
              #d="ngbDatepicker"
              class="form-control"
              formControlName="availableFrom"
              name="dp"
              ngbDatepicker
              placeholder="yyyy-mm-dd"
            />
            <button (click)="d.toggle()" class="btn btn-outline-secondary bi bi-calendar3" type="button"></button>
          </div>
        </div>
      }
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Har du eget företag?</label>
    <div aria-label="Vilken anställningsform kan du ha?" role="group">
      @for (option of OwnCompany; track option; let i = $index) {
        <div class="form-check">
          <input [id]="'ownCompanyOption-' + (i + 1)" [value]="option.key" class="form-check-input"
                 formControlName="ownCompany"
                 type="radio">
          <label [for]="'ownCompanyOption-' + (i + 1)" class="form-check-label">{{ option.text }}</label>
        </div>
      }
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Vilken uppdragsform önskar du?</label>
    <div aria-label="Vilken uppdragsform önskar du?" role="group">
      @for (option of AssignmentType; track option; let i = $index) {
        <div class="form-check">
          <input [id]="'assignmentType-' + (i + 1)" [value]="option.key" class="form-check-input"
                 formControlName="assignmentType"
                 type="radio">
          <label [for]="'assignmentType-' + (i + 1)" class="form-check-label">{{ option.text }}</label>
        </div>
      }
    </div>
  </fieldset>

  <fieldset formArrayName="languages">
    <label class="form-label">Vilka språk behärskar du?</label>
    <div *ngFor="let language of languages.controls as FormGroup; let i=index" [formGroupName]="i" class="mt-2">
      <div class="fw-bold">{{ language.get('language')?.getRawValue().name }}</div>
      <div class="d-flex">
        <div>Ingen kunskap</div>
        <div class="flex-grow-1 ms-3"><input class="form-range" formControlName="level" id="language-{{ i }}" max="3"
                                             min="0"
                                             type="range"></div>
        <div class="ms-3">Flytande</div>
      </div>
    </div>
  </fieldset>

  <fieldset formArrayName="positions">
    <div class="row">
      <div class="col-12">
        <label class="form-label">Lägg till de roller du har haft, på vilka företag och antal år</label>
        <table class="table table-striped table-bordered">
          <thead>
          <th>Roll</th>
          <th>Företag</th>
          <th>Antal år</th>
          <th></th>
          </thead>
          <tbody>
          <tr *ngFor="let position of positions.controls as FormGroup; let i=index" [formGroupName]="i">
            <td class="align-middle">{{ position.get('role')?.getRawValue().name }}</td>
            <td>
              <input class="form-control" formControlName="companyName" placeholder="Företagets namn">
            </td>
            <td>
              <input class="form-control" formControlName="years" id="years-{{ i }}" max="70"
                     min="1" placeholder="Antal år" type="number">
            </td>
            <td class="align-middle text-end">
              <i (click)="removePosition(i)" class="bi-trash remove-icon-button hover-pointer"></i>
            </td>
          </tr>
            @if (!positions.length) {
              <tr>
                <td colspan="4">Lägg till befattningar i sökrutan nedan.</td>
              </tr>
            }
          </tbody>
        </table>
        <input
          #role="ngbTypeahead"
          #roleInput
          (click)="roleClick$.next($any($event).target.value)"
          (focus)="roleFocus$.next($any($event).target.value)"
          (selectItem)="onSelectRole($event, roleInput)"
          [editable]="false"
          [inputFormatter]="typeaheadFormatter"
          [ngbTypeahead]="searchRoles"
          [resultFormatter]="typeaheadFormatter"
          class="form-control"
          id="typeahead-role"
          placeholder="Börja skriv för fler förslag på roller"
          type="text"
        />
      </div>
    </div>
  </fieldset>

  <fieldset>
    <div class="row">
      <div class="col">
        <label class="form-label" for="typeahead-location">Vilka interimroller skulle du vilja ha?</label>
        <table class="table table-striped table-bordered">
          <tbody>
            @for (wantedInterimRole of wantedInterimRoles.controls; track wantedInterimRole; let i = $index) {
              <tr
                formArrayName="wantedInterimRoles">
                <td><label for="location-{{ i }}">{{ wantedInterimRole.getRawValue().name }}</label></td>
                <td class="text-end"><i (click)="removeWantedInterimRole(i)"
                                        class="bi-trash remove-icon-button hover-pointer"></i>
                </td>
              </tr>
            }
          </tbody>
        </table>
        <input
          #wantedInterimRole="ngbTypeahead"
          #wantedInterimRoleInput
          (click)="wantedInterimRoleClick$.next($any($event).target.value)"
          (focus)="wantedInterimRoleFocus$.next($any($event).target.value)"
          (selectItem)="onSelectWantedInterimRole($event, wantedInterimRoleInput)"
          [editable]="false"
          [inputFormatter]="typeaheadFormatter"
          [ngbTypeahead]="searchWantedInterimRoles"
          [resultFormatter]="typeaheadFormatter"
          class="form-control"
          id="typeahead-wanted-interim-role"
          placeholder="Börja skriv för fler förslag på roller"
          type="text"
        />
      </div>
      <div class="col"></div>
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Hur stor budget har du haft ansvar för?</label>
    <div aria-label="Hur stor budget har du haft ansvar för?" role="group">
      <select class="form-select" formControlName="budgetLevels">
        @for (level of BudgetLevels; track level) {
          <option [value]="level.key">{{ level.text }}</option>
        }
      </select>
    </div>
  </fieldset>

  <fieldset>
    <div class="form-check form-switch">
      <input class="form-check-input" formControlName="employeeAppraisals" id="employeeAppraisals"
             type="checkbox">
      <label class="form-check-label" for="employeeAppraisals">Jag har haft ansvar för medarbetarsamtal</label>
    </div>
  </fieldset>

  <fieldset>
    <div class="form-check form-switch mb-3">
      <input class="form-check-input" formControlName="salaryNegotiations" id="salaryNegotiations"
             type="checkbox">
      <label class="form-check-label" for="salaryNegotiations">Jag har haft ansvar för lönesamtal</label>
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Erfarenhet av att leda personer direkt</label>
    <div aria-label="Hur stor budget har du haft ansvar för?" role="group">
      <select class="form-select" formControlName="manageDirectLevel">
        @for (level of ManageDirectLevels; track level) {
          <option [value]="level.key">{{ level.text }}</option>
        }
      </select>
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Erfarenhet av att leda personer indirekt</label>
    <div aria-label="Hur stor budget har du haft ansvar för?" role="group">
      <select class="form-select" formControlName="manageIndirectLevel">
        @for (level of ManageIndirectLevels; track level) {
          <option [value]="level.key">{{ level.text }}</option>
        }
      </select>
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Önskad fas för organisationen</label>
    <div class="form-check form-switch">
      <div>
        <input class="form-check-input" formControlName="orgPhaseStartup" id="orgPhaseStartup" type="checkbox">
        <label class="form-check-label" for="orgPhaseStartup">Uppstart</label>
      </div>
      <div>
        <input class="form-check-input" formControlName="orgPhaseScaleup" id="orgPhaseScaleup" type="checkbox">
        <label class="form-check-label" for="orgPhaseScaleup">Uppskalning</label>
      </div>
      <div>
        <input class="form-check-input" formControlName="orgPhaseStable" id="orgPhaseStable" type="checkbox">
        <label class="form-check-label" for="orgPhaseStable">Stabilt</label>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Föredragen storlek på organisationen</label>
    <div aria-label="Föredragen storlek på organisationen" role="group">
      <select class="form-select" formControlName="orgSize">
        @for (level of OrgSizes; track level) {
          <option [value]="level.key">{{ level.text }}</option>
        }
      </select>
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Vad föredrar du för interimsuppdrag?</label>
    <div class="form-check form-switch">
      <div>
        <input class="form-check-input" formControlName="preferInterimCoverDuringRecruitment"
               id="preferInterimCoverDuringRecruitment" type="checkbox">
        <label class="form-check-label" for="preferInterimCoverDuringRecruitment">Täcka upp under en
          rekryteringsprocess</label>
      </div>
      <div>
        <input class="form-check-input" formControlName="preferInterimCoverDuringSickleave"
               id="preferInterimCoverDuringSickleave" type="checkbox">
        <label class="form-check-label" for="preferInterimCoverDuringSickleave">Täcka upp för sjukfrånvaro</label>
      </div>
      <div>
        <input class="form-check-input" formControlName="preferInterimLeadChange" id="preferInterimLeadChange"
               type="checkbox">
        <label class="form-check-label" for="preferInterimLeadChange">Leda utvecklings- och
          förändringsarbete</label>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Välj vilka två alternativ du drivs mest av</label>
    <div aria-label="Välj vilka två alternativ du drivs mest av" class="row">
      <div class="col-md-6">
        <select class="form-select" formControlName="myNeedsIndicator1">
          <option value=""></option>
          @for (indicator of MyNeedsIndicators; track indicator) {
            <option [value]="indicator.key">{{ indicator.text }}</option>
          }
        </select>
      </div>
      <div class="col-md-6">
        <select class="form-select col" formControlName="myNeedsIndicator2">
          <option value=""></option>
          @for (indicator of MyNeedsIndicators; track indicator) {
            <option [value]="indicator.key">{{ indicator.text }}</option>
          }
        </select>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Välj vad som stämmer mest in på dig</label>
    <div aria-label="Välj vad som stämmer mest in på dig" role="group">
      <select class="form-select" formControlName="valueAsLeader">
        <option value=""></option>
        @for (value of ValuesAsLeader; track value) {
          <option [value]="value.key">{{ value.text }}</option>
        }
      </select>
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Hur tänker du kring att leda organisatorisk förändring?</label>
    <div aria-label="Hur tänker du kring att leda organisatorisk förändring?" role="group">
      <select class="form-select" formControlName="changingOrganizations">
        <option value=""></option>
        @for (value of ChangingOrganizations; track value) {
          <option [value]="value.key">{{ value.text }}</option>
        }
      </select>
    </div>
  </fieldset>

  <h2 class="border--bottom">Ta ställning till följande påståenden</h2>

  <fieldset>
    <label class="form-label">Att sätta kontext.</label><br>
    Som ledare behöver jag förstå kontexten och helhet för att förstå situationen och kommunicera till andra.
    <div class="d-flex mt-3">
      <div>Stämmer inte alls</div>
      <div class="flex-grow-1 ms-3"><input class="form-range" formControlName="valueContext" id="valueContext" max="4"
                                           min="1"
                                           type="range"></div>
      <div class="ms-3">Stämmer helt</div>
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Att förstå perspektiv hos intressenter.</label><br>
    Som ledare håller jag tillbaka mina åsikter och faciliterar intressenterna för att de ska se varandras perspektiv
    och hitta vägar framåt.
    <div class="d-flex mt-3">
      <div>Stämmer inte alls</div>
      <div class="flex-grow-1 ms-3"><input class="form-range" formControlName="valuePerspective" id="valuePerspective"
                                           max="4"
                                           min="1" type="range"></div>
      <div class="ms-3">Stämmer helt</div>
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Hitta nya lösningar.</label><br>
    Som ledare reflekterar jag kring mina antaganden och är nyfiken på hur saker kan göras annorlunda, speciellt kring
    komplexa problem.
    <div class="d-flex mt-3">
      <div>Stämmer inte alls</div>
      <div class="flex-grow-1 ms-3"><input class="form-range" formControlName="valueSolutions" id="valueSolutions"
                                           max="4" min="1"
                                           type="range"></div>
      <div class="ms-3">Stämmer helt</div>
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Självledarskap.</label><br>
    Som ledare reflekterar jag dagligen över mitt eget beteende och mina reaktioner, tankar och känslor som händelser
    väcker i mig och sedan försöker jag prova nya sätt att agera på.
    <div class="d-flex mt-3">
      <div>Stämmer inte alls</div>
      <div class="flex-grow-1 ms-3"><input class="form-range" formControlName="valueSelfLeadership"
                                           id="valueSelfLeadership"
                                           max="4" min="1"
                                           type="range"></div>
      <div class="ms-3">Stämmer helt</div>
    </div>
  </fieldset>

  <h2 class="border--bottom">Hur vill du att vi kontaktar dig?</h2>

  <fieldset>
    <label class="form-label">E-postadress</label>
    <div aria-label="E-postadress" role="group">
      <input class="form-control" email formControlName="contactEmail" type="email">
    </div>
  </fieldset>

  <fieldset>
    <label class="form-label">Telefonnummer</label>
    <div aria-label="Telefonnummer" role="group">
      <input class="form-control" formControlName="phoneNumber" type="text">
    </div>
  </fieldset>

  <div class="invalid-feedback-summary mb-3">
    Kontrollera rödmarkerade fält ovan för att kunna spara.
  </div>
  <button (click)="save()" class="btn btn-primary btn-lg" type="submit">{{ saveButtonText() }}</button>
</form>

<ng-template #content let-modal>
  <form name="newIndustryForm">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Saknar du din bransch i listan?</h4>
      <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button"></button>
    </div>
    <div class="modal-body">
      <p>Låt oss veta så att vi kan göra Moderna Ledare bättre.</p>
      <form>
        <div class="mb-3">
          <input [(ngModel)]="newIndustry" class="form-control" name="newIndustry"
                 placeholder="Skriv namnet på den bransch du saknar">
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button (click)="modal.close('Save click')" class="btn btn-primary" type="button">Skicka</button>
    </div>
  </form>
</ng-template>
