import { ApplicationConfig, mergeApplicationConfig } from '@angular/core';
import { provideToastr } from "ngx-toastr";
import { provideAnimations } from "@angular/platform-browser/animations";
import { appConfig } from "./app.config";


const browserConfig: ApplicationConfig = {
  providers: [
    provideToastr(),
    provideAnimations()
  ]
};

export const config = mergeApplicationConfig(appConfig, browserConfig);

