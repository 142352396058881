import { Routes } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { StartComponent } from "./start/start.component";
import { RegisterComponent } from "./profile/register/register.component";
import { authGuard } from "./shared/auth-guards/authGuard";
import { CandidateListComponent } from "./matching/candidate-list/candidate-list.component";
import { ProfileFrameComponent } from "./profile/profile-frame/profile-frame.component";
import { MyPageComponent } from "./profile/my-page/my-page.component";
import { ConfirmCandidateComponent } from "./confirm/confirm-candidate/confirm-candidate.component";
import { ConfirmThankYouComponent } from "./confirm/confirm-thank-you/confirm-thank-you.component";
import { DeleteProfileComponent } from "./profile/delete-profile/delete-profile.component";
import { startGuard } from "./shared/auth-guards/startGuard";
import { IntroductionComponent } from "./profile/introduction/introduction.component";
import { ConfirmationComponent } from "./profile/confirmation/confirmation.component";
import { UploadCVComponent } from "./profile/upload-cv/upload-cv.component";
import { ProfileDeletedConfirmationComponent } from "./profile-deleted/profile-deleted-confirmation.component";

export const routes: Routes = [
  { path: '', component: StartComponent, canActivate: [startGuard] },
  { path: 'login', component: LoginComponent },
  {
    path: 'profil', component: ProfileFrameComponent, canActivate: [authGuard], children: [
      { path: '', component: MyPageComponent },
      { path: 'introduktion', component: IntroductionComponent },
      { path: 'registrera-dig', component: RegisterComponent },
      { path: 'bekrafta', component: ConfirmationComponent },
      { path: 'cv', component: UploadCVComponent },
      { path: 'radera', component: DeleteProfileComponent },
    ]
  },
  { path: 'bekrafta/:candidateId', component: ConfirmCandidateComponent, canActivate: [authGuard] },
  { path: 'bekraftad', component: ConfirmThankYouComponent, canActivate: [authGuard] },
  { path: 'profil-raderad', component: ProfileDeletedConfirmationComponent },
  { path: 'kandidater', component: CandidateListComponent, canActivate: [authGuard] }
];
