import { Component } from '@angular/core';

import { RouterLink } from "@angular/router";
import { ConfirmPresentationComponent } from "../confirm-presentation/confirm-presentation.component";
import { SupportInfoComponent } from "../../shared/support-info/support-info.component";

@Component({
  selector: 'app-validate-thank-you',
  standalone: true,
  imports: [RouterLink, ConfirmPresentationComponent, SupportInfoComponent],
  templateUrl: './confirm-thank-you.component.html',
  styleUrl: './confirm-thank-you.component.scss'
})
export class ConfirmThankYouComponent {

}
